import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GtmService {
    public pushEvent(eventName: string, eventData: any = {}): void {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            event: eventName,
            ...eventData
        });
    }
}
